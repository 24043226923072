import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "./technologies.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	EffectCoverflow,
	FreeMode,
	Grid,
	Autoplay,
} from "swiper/modules";
import "swiper/css/free-mode";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/grid";
import "swiper/css/effect-coverflow";
import { useSelector } from "react-redux";

const Technologies = () => {
	const [slidesPerView, setSlidesPerView] = useState(4);
	const technologies = useSelector((res) => res.data.technologies);
	const test = useSelector((res) => res.data);
	// console.log(test.technologies);

	useEffect(() => {
		if (window.innerWidth < 768) {
			setSlidesPerView(3);
		} else {
			setSlidesPerView(5);
		}
	}, []);

	return (
		<>
			<h2 className="section__title tech-section">Technologies</h2>
			<div className="technologies container section">
				<Swiper
					style={{ display: "flex", alignItems: "center" }}
					spaceBetween={10}
					grabCursor={true}
					centeredSlides={true}
					slidesPerView={slidesPerView}
					// loop={true}
					autoplay={{
						delay: 500000,
						disableOnInteraction: false,
					}}
					pagination={{ clickable: true }}
					navigation={false}
					modules={[Pagination, Navigation, FreeMode, Autoplay]}
					effect={"coverflow"}
					speed={1000}
					className="mySwiper">
					{technologies &&
						technologies.map((ele) => {
							return (
								<SwiperSlide key={ele.id}>
									<div
										className="tech-wrapper"
										style={{ display: "flex", alignItems: "center" }}>
										<img
											src={ele.image?.url}
											alt={ele.name}
											width={100}
											height={100}
											className="tech-img"
										/>
									</div>
								</SwiperSlide>
							);
						})}
				</Swiper>
			</div>
		</>
	);
};

export default Technologies;
