import React from "react";
import { useSelector } from "react-redux";

const AboutBox = () => {
	// let bigData = useSelector((res) => res.data?.about);
	// let midData = bigData ? bigData[0] : null;
	// let data = midData?.aboutInfo;

	let data = useSelector((state) => state.data.about);

	let projectComplete;
	let cupOfCoffee;
	let statisfiedClients;
	let NomineesWinner;

	// console.log(data);
	let alldata =
		data &&
		data.map((ele) => {
			if (ele.title == "projectsCompleted") {
				projectComplete = ele.count;
			}
			if (ele.title == "cupsOfCoffee") {
				cupOfCoffee = ele.count;
			}
			if (ele.title == "satisfiedClients") {
				statisfiedClients = ele.count;
			}
			if (ele.title == "nomineesWinner") {
				NomineesWinner = ele.count;
			}
		});
	return (
		<div className="about__boxes grid">
			<div className="about__box">
				<i className="about__icon icon-fire"></i>
				<div>
					<h3 className="about__title">{projectComplete}</h3>
					<span className="about__subtitle"> Project Complete</span>
				</div>
			</div>

			<div className="about__box">
				<i className="about__icon icon-cup"></i>
				<div>
					<h3 className="about__title"> {cupOfCoffee}</h3>
					<span className="about__subtitle"> Cup of Coffee</span>
				</div>
			</div>

			<div className="about__box">
				<i className="about__icon icon-people"></i>
				<div>
					<h3 className="about__title">{statisfiedClients}</h3>
					<span className="about__subtitle"> Satisfied Clients</span>
				</div>
			</div>

			<div className="about__box">
				<i className="about__icon icon-badge"></i>
				<div>
					<h3 className="about__title">{NomineesWinner}</h3>
					<span className="about__subtitle">Nominees winner</span>
				</div>
			</div>
		</div>
	);
};
export default AboutBox;
