import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jsonFile from "../../data/data.json";
import GloableApi from "../../utils/GloableApi";

export const fetchData = createAsyncThunk("fetchData", async () => {
	// Call the GraphQL request function
	const data = await GloableApi.getAllData();

	// console.log(data.all_Data?.[0]);
	return data.all_Data?.[0];
});

const data = createSlice({
	name: "services",
	initialState: [{ id: 1, name: "test" }],
	reducers: {},
	extraReducers: (builder) => {
		// console.log("action");

		builder.addCase(fetchData.rejected, (state, action) => {
			console.error("fetch data faild", action.error.message);
			console.log("reject ", action.payload);
		});

		builder.addCase(fetchData.fulfilled, (initialState, action) => {
			// console.log(action);
			return action.payload;
		});
	},
});
// export const fData = fetchData.reducers;
export default data.reducer;
