import { gql, request } from "graphql-request";

const MASTER_ENDPOINT =
	"https://eu-west-2.cdn.hygraph.com/v2/clyuo1xby029s06w969cmtun8/master";

const getAllData = async () => {
	const query = gql`
		query portfolio {
			all_Data {
				author {
					facebook
					inspect
					instagram
					jobTitle
					linkedIn
					name
					title
					whatsapp
					cv {
						url
					}
					image1 {
						url
					}
					image2 {
						url
					}
					image3 {
						url
					}
					emails
				}
				experience {
					id
					category
					company
					desc
					icon
					title
					year
					logo {
						url
					}
				}
				pricing {
					title
					advantages
					disadvantages
					discount
					discountFor
					price
					image {
						url
					}
				}
				reviews {
					id
					image {
						url
					}
					title
					subtitle
					comment
				}
				service {
					id
					title
					discription
					image {
						url
					}
				}
				skills {
					title
					percentage
					color
				}
				technologies {
					id
					title
					image {
						url
					}
				}
				work {
					id
					image {
						url
					}
					link
					title
					projectName
					category
				}
				about {
					title
					count
					aboutDescription
				}
			}
		}
	`;

	const result = await request(MASTER_ENDPOINT, query);
	return result;
};
export default {
	getAllData,
};
